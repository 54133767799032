import 'bulma/css/bulma.min.css'
import './App.scss'
import React, { Component } from 'react'
import Cookies from 'js-cookie'
import moment from 'moment'
import liff from '@line/liff'
import 'moment/locale/th'; // without this line it didn't work

moment.locale('th')

const endpoint = 'https://wfhipst.hitevent.com/wfh-server'
// const endpoint = 'http://localhost:8080'

function NewlineText(props) {
  const textWithLineBreaks = props.text.split('\n');
  return textWithLineBreaks.map((line, index) => (
    <React.Fragment key={index}>
      <p>{line}</p>
      {/* {index < textWithLineBreaks.length - 1 && <br />} */}
    </React.Fragment>
  ));
}
function CustomConfirm({ message, onConfirm, onCancel }) {
  // onConfirm = useDebouncedCallback(e => onConfirm && onConfirm(e), 1000)
  // onCancel = useDebouncedCallback(e => onCancel && onCancel(e), 1000)
  let [processing, setProcessing] = React.useState(false)

  return (
    <div className="confirm-overlay">
      <div className="confirm-box">
        <NewlineText text={message} />
        {!processing && onConfirm && onCancel && <button className="confirm-button" disabled={processing} onClick={async (e) => {
          setProcessing(true);
          await onConfirm(e);
          setProcessing(false);
        }}>ใช่</button>}
        {!processing && onConfirm && !onCancel && <button className="confirm-button" disabled={processing} onClick={async (e) => {
          setProcessing(true);
          await onConfirm(e);
          setProcessing(false);
        }}>ตกลง</button>}
        {!processing && onCancel && <button disabled={processing} onClick={async (e) => {
          setProcessing(true);
          await onCancel(e);
          setProcessing(false);
        }}>ไม่ใช่</button>}
      </div>
    </div>
  );
}

class App extends Component {
  state = {
    user: null,
    page: 'login',
    auth_token: ""
  }

  async componentDidMount() {
    let user = null
    // let user = Cookies.get('user') || null
    Cookies.remove('user')
    let page = 'login'
    if (user) {
      const rawResponse = await fetch(`${endpoint}/wfh/privacy/${user}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      const content = await rawResponse.json()

      if (content.result === 'success') {
        page = 'checkin'
      }


    }
    this.setState({ user, page })

    liff.init({ liffId: '1655088062-XmkQgRz1' }, async () => {
      if (!liff.isInClient()) {
        if (window.confirm("กรุณาใช้งานผ่าน LINE APP เท่านั้น")) {
          this.setState({ page: "not_line" })
          window.close();
        }
      }

      if (!liff.isLoggedIn()) {
        liff.login()
      }
      else {
        let profile = await liff.getProfile()
        window.line_id = profile.userId
      }
    })
  }

  render() {
    let { page, user, auth_token } = this.state
    // user = 'snaks'
    // auth_token = 'a68083b2070db67be229b7cdba186183e3d909ff'
    // page = 'history'

    if (page === 'not_line') {
      return <div className="privacy-policy">กรุณาใช้งานผ่าน LINE APP เท่านั้น</div>
    }
    return (
      <>
        {(page === 'login' || !user) && (
          <Login
            onAuth={async (user, auth_token) => {
              Cookies.set('user', user)
              this.setState({ auth_token })
              console.log(auth_token)
              let accepted_policy = false
              const rawResponse = await fetch(`${endpoint}/wfh/privacy/${user}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'HMAC-SHA ' + auth_token
                },
              })
              const content = await rawResponse.json()

              if (content.result === 'success') {
                accepted_policy = true
              }

              if (accepted_policy)
                this.setState({ user, page: 'checkin' })
              else
                this.setState({ user, page: 'policy' })
            }}
          />
        )}
        {page === 'policy' && user && (
          <div className="privacy-policy">
            <h1 className="title">ข้อตกลงและเงื่อนไข</h1>
            <h3 className="subtitle">วัตถุประสงค์</h3>
            <p>ระบบลงเวลาการปฏิบัติงาน ใช้เพื่อการบันทึกเวลาเข้าออกของบุคลากร สสวท.และติดตามการปฏิบัติงานของบุคลากรระหว่างการปฏิบัติงานที่บ้าน โดยเก็บข้อมูล ชื่อ นามสกุล วันที่ เวลา และสถานที่ปัจจุบัน โดย
              เป็นไปตามนโยบายและแนวปฏิบัติการปฏิบัติงานที่บ้านของ สสวท. </p>
            <br />
            <h3 className="subtitle">วิธีการเก็บรักษาข้อมูลส่วนบุคคล</h3>
            <p>สสวท.จะเก็บรักษาข้อมูลส่วนบุคคลของคุณในรูปแบบเอกสารและอิเล็กทรอนิกส์ โดยนำไปใช้ตามวัตถุประสงค์ข้างต้น</p>
            <br />
            <h3 className="subtitle">การรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล</h3>
            <p>เราจะรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของคุณไว้ตามหลักการ การรักษาความลับ (confidentiality) ความถูกต้องครบถ้วน (integrity) และสภาพพร้อมใช้งาน (availability) ทั้งนี้ เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผย นอกจากนี้เราจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ (administrative safeguard) มาตรการป้องกันด้านเทคนิค (technical safeguard) และมาตรการป้องกันทางกายภาพ (physical safeguard) ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access control)</p>
            <br />
            <h3 className="subtitle">การแก้ไขเปลี่ยนแปลงถือเป็นการบอกกล่าว</h3>
            <p>หากท่านไม่สามาถปฏิบัติตามข้อตกลง และเงื่อนไขการเข้าใช้งานระบบดังกล่าว ผู้ดูแลระบบสามารถยกเลิก หรือ เปลี่ยนแปลงสิทธิ์การใช้งานของท่านได้</p>
            <br />
            <button className="button is-success is-fullwidth" onClick={async () => {
              const rawResponse = await fetch(`${endpoint}/wfh/privacy/${user}/${window.line_id}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'HMAC-SHA ' + auth_token
                },
              })
              const content = await rawResponse.json()

              if (content.result === 'success') {
                this.setState({ user, page: 'checkin' })
              }

            }}>ยอมรับ</button>
          </div>
        )}
        {page === 'checkin' && user && (
          <Checkin
            user={user}
            auth_token={auth_token}
            onShowHistory={() => this.setState({ page: 'history' })}
            onLogout={() => {
              Cookies.remove('user')
              this.setState({ user: null, page: 'login' })
            }}
          />
        )}
        {page === 'history' && user && (
          <History
            user={user}
            auth_token={auth_token}
            onClose={() => this.setState({ page: 'checkin' })}
            onLogout={() => {
              Cookies.remove('user')
              this.setState({ user: null, page: 'login' })
            }}
          />
        )}
      </>
    )
  }
}

class Login extends Component {
  state = {
    user: '',
    pass: '',
    errorMessage: null
  }

  async auth() {
    let { user, pass } = this.state

    const rawResponse = await fetch(`${endpoint}/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user, pass })
    })
    const content = await rawResponse.json()

    if (content.result === 'success') {
      this.setState({ auth_token: content.auth_token })
      this.props.onAuth && this.props.onAuth(user, content.auth_token)
    } else {
      this.setState({ errorMessage: 'ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง!' })
    }
  }

  render() {
    let { errorMessage, user, pass } = this.state
    return (
      <div className='App'>
        <img src='/logo512.png' className='logo' />
        <h1 className='title'>
          ระบบลงเวลาพนักงาน
          <br />
          IPST Mobile Time Attendance
        </h1>
        <h3 className='subtitle' style={{ paddingTop: 5 }}>
          สถาบันส่งเสริมการสอน
          <br />
          วิทยาศาสตร์ และ เทคโนโลยี (สสวท.)
        </h3>
        <div className='login-form has-text-left'>
          <div className='field'>
            <label className='label'>ชื่อผู้ใช้</label>
            <div className='control has-icons-left has-icons-right'>
              <input
                className='input is-small'
                type='text'
                placeholder=''
                onChange={e =>
                  this.setState({ user: e.target.value.toLowerCase() })
                }
                value={user}
              />
              <span className='icon is-small is-left'>
                <i className='fas fa-user'></i>
              </span>
              <span className='icon is-small is-right'>
                <i className='fas fa-check'></i>
              </span>
            </div>
          </div>

          <div className='field'>
            <label className='label'>รหัสผ่าน</label>
            <div className='control has-icons-left has-icons-right'>
              <input
                className='input is-small'
                type='password'
                placeholder=''
                onChange={e => this.setState({ pass: e.target.value })}
                value={pass}
              />
              <span className='icon is-small is-left'>
                <i className='fas fa-envelope'></i>
              </span>
              <span className='icon is-small is-right'>
                <i className='fas fa-exclamation-triangle'></i>
              </span>
            </div>
          </div>
          {errorMessage && <p className='help is-danger'>{errorMessage}</p>}

          <div className='field'>
            <div className='control'>
              <button
                className='button is-link is-fullwidth is-primary is-small'
                onClick={() => this.auth()}
              >
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Checkin extends Component {
  state = { wfhlist: [], location: null, mode: 'check-in', userData: null, confirmMessage: null, processing: false }

  async componentDidMount() {
    let { user, auth_token } = this.props
    navigator.geolocation.getCurrentPosition(
      location => {
        console.log(location.coords)
        this.setState({ location: location.coords })
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            alert("ไม่สามารถเข้าถึงตำแหน่งของคุณได้!\nUser denied the request for Geolocation."); break;
          case error.POSITION_UNAVAILABLE:
            alert("ไม่สามารถเข้าถึงตำแหน่งของคุณได้!\nLocation information is unavailable."); break;
          case error.TIMEOUT:
            alert("ไม่สามารถเข้าถึงตำแหน่งของคุณได้!\nThe request to get user location timed out. "); break;
          case error.UNKNOWN_ERROR:
            alert("ไม่สามารถเข้าถึงตำแหน่งของคุณได้!\nAn unknown error occurred."); break;
          default:
            alert("ไม่สามารถเข้าถึงตำแหน่งของคุณได้!\nGeolocation is not supported by this browser.")
            break;
        }
      }
    )

    const rawResponse = await fetch(`${endpoint}/wfhlist/${user}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'HMAC-SHA ' + auth_token
      }
      // body: JSON.stringify(this.state)
    })
    const content = await rawResponse.json()
    let wfhlist = content.data
    console.log(wfhlist)
    let userData = wfhlist[0]

    const histRawResponse = await fetch(`${endpoint}/wfhhistory/${user}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'HMAC-SHA ' + auth_token
      }
      // body: JSON.stringify(this.state)
    })
    const histContent = await histRawResponse.json()
    console.log(
      histContent.data.filter(x =>
        moment(x.WFH_DATE).isSame(new Date(), 'days')
      )
    )
    let mode =
      histContent.data.filter(x =>
        moment(x.WFH_DATE).isSame(new Date(), 'days')
      ).length %
        2 ==
        0
        ? 'check-in'
        : 'check-out'

    this.setState({ wfhlist, userData, mode })
  }


  async checkin() {
    let { user, auth_token } = this.props
    let { userData, location, mode } = this.state

    this.setState({
      processing: true,
      confirmMessage: {
        message: `คุณต้องการบันทึกเวลา "${mode === 'check-in' ? "เช็คอิน" : "เช็คเอาท์"}" ใช่หรือไม่?`,
        onConfirm: async () => {
          let resp = await fetch(
            `${endpoint}/wfhsave/${mode}/${user}/${location.latitude}/${location.longitude}/${userData.EMP_ID}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'HMAC-SHA ' + auth_token
            }
            // body: JSON.stringify(this.state)
          })

          let result = await resp.json()

          this.setState({
            confirmMessage: {
              message: `บันทึกข้อมูลเรียบร้อยแล้ว!\n${userData.EMP_NAME}\n${moment(result.data.WFH_DATE).format('DD-MM-YYYY')} ${result.data.CHECKIN_TIME}\n${result.data.DISTRICT_NAME}, ${result.data.PROVINCE_NAME}`,
              onConfirm: () => {

                if (mode === 'check-in') this.setState({ mode: 'check-out' })
                else this.setState({ mode: 'check-in' })

                this.setState({ processing: false, confirmMessage: null })
              }
            }
          })
        },
        onCancel: () => {
          this.setState({ processing: false, confirmMessage: null })
        }
      }
    })

  }

  render() {
    let { wfhlist, location, mode, processing } = this.state
    let hasWFH =
      wfhlist.length > 0 &&
      wfhlist.filter(x => moment(x.WFHDATE_DATE).isSame(new Date(), 'day'))
        .length > 0

    return (
      <>
        <div className='App Login'>
          {!hasWFH && (
            <div className='notification is-warning'>
              คุณไม่มีรายการ Work From Home ในวันนี้
              <div>{moment().format('LLLL')}</div>
            </div>
          )}
          {hasWFH && !location && <div>กำลังค้นหาตำแหน่งของคุณ...</div>}
          {hasWFH && location && (
            <>
              <h1 className='title'>จุดเช็คอิน</h1>
              <img
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${location.latitude},${location.longitude}&markers=color:red:%7C${location.latitude},${location.longitude}&zoom=18&size=400x400&key=AIzaSyCLAsBm9LduxGU3qL5Yl2uzk5pX_8dtRMs`}
              />

              <div style={{ margin: 15, fontSize: '1.2em' }}>
                <div style={{ fontWeight: 'bold' }}>{wfhlist[0].EMP_NAME}</div>
                <div>{moment().format('LLLL')}</div>
              </div>
              {!processing && <>
                {mode === 'check-in' && (
                  <button
                    className='button is-success is-fullwidth'
                    onClick={() => this.checkin()}
                  >
                    เช็คอิน
                  </button>
                )}
                {mode === 'check-out' && (
                  <button
                    className='button is-danger is-fullwidth'
                    onClick={() => this.checkin()}
                  >
                    เช็คเอาท์
                  </button>
                )}
              </>}
            </>
          )}
        </div>
        <div className='columns is-mobile is-variable  is-1'>
          <div className='column'>
            <button
              className='button is-primary is-fullwidth'
              onClick={() => this.props.onShowHistory()}
            >
              ประวัติการเช็คอิน-เช็คเอาท์
            </button>
          </div>
          <div className='column'>
            <button
              className='button is-warning is-fullwidth'
              onClick={() => this.props.onLogout()}
            >
              ออกจากระบบ
            </button>
          </div>
        </div>

        {this.state.confirmMessage && <CustomConfirm {...this.state.confirmMessage} />}
      </>
    )
  }
}

class History extends Component {
  state = { wfhlist: [] }
  async componentDidMount() {
    let { user, auth_token } = this.props
    const rawResponse = await fetch(`${endpoint}/wfhhistory/${user}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'HMAC-SHA ' + auth_token
      }
      // body: JSON.stringify(this.state)
    })
    const content = await rawResponse.json()
    let wfhlist = content.data.sort((a, b) =>
      (moment(b.WFH_DATE.slice(0, 10) + ' ' + b.CHECKIN_TIME).format() + b.CHECK_TYPE).localeCompare(
        (moment(a.WFH_DATE.slice(0, 10) + ' ' + a.CHECKIN_TIME).format() + a.CHECK_TYPE)
      )
    )
    this.setState({ wfhlist })
    console.log(wfhlist)
  }
  render() {
    let { wfhlist } = this.state
    return (
      <>
        <div className='App Login'>
          {!wfhlist ||
            (wfhlist.length === 0 && (
              <div className='notification is-warning'>
                คุณไม่มีรายการ Work From Home ในวันนี้
              </div>
            ))}
          {wfhlist && wfhlist.length > 0 && (
            <>
              <h1 className='title'>ประวัติการเช็คอิน-เช็คเอาท์</h1>
              {wfhlist.map(x => (
                <div className='checkin-item has-text-left'>
                  <div className='columns is-mobile'>
                    <div className='column'>
                      {moment(x.WFH_DATE).format('LL')}
                    </div>
                    <div className='column has-text-right'>
                      {x.CHECKIN_TIME} น.
                    </div>
                  </div>
                  <div>
                    <div className='columns is-mobile'>
                      <div className='column is-two-thirds'>
                        <small>
                          {x.DISTRICT_NAME}, {x.PROVINCE_NAME}
                        </small>
                      </div>
                      <div
                        className={`column has-text-right ${x.CHECK_TYPE == 'check-in'
                          ? 'has-text-success'
                          : 'has-text-danger'
                          }`}
                      >
                        {x.CHECK_TYPE == 'check-in' ? 'เข้า' : 'ออก'}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className='columns is-mobile is-variable  is-1'>
          <div className='column'>
            <button
              className='button is-primary is-fullwidth'
              onClick={() => this.props.onClose()}
            >
              ย้อนกลับ
            </button>
          </div>
          <div className='column'>
            <button
              className='button is-warning is-fullwidth'
              onClick={() => this.props.onLogout()}
            >
              ออกจากระบบ
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default App
